import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
// components
import Layout from '../components/layout';
import SEO from '../components/seo';

// services
import styles from './recycle.module.scss';
import Image from '@components/image/image';

const RecyclePage = () => {
  const staticData = useStaticQuery(graphql`
    query Recycle {
      directusRecycle {
        page_title
        page_description
        hero_title
        hero_description
        hero_image {
          filename_disk
        }
        step_0_title
        step_0_description
        step_1_title
        step_1_description
        step_2_title
        step_2_description
        step_3_title
        step_3_description
        step_4_title
        step_4_description
        step_5_title
        step_5_description
        step_6_title
        step_6_description
        step_7_title
        step_7_description
        step_7_image {
          filename_disk
        }
      }
    }
  `);

  const { directusRecycle } = staticData;
  const {
    page_title,
    page_description,
    hero_title,
    hero_description,
    hero_image,
    step_0_title,
    step_0_description,
    step_1_title,
    step_1_description,
    step_2_title,
    step_2_description,
    step_3_title,
    step_3_description,
    step_4_title,
    step_4_description,
    step_5_title,
    step_5_description,
    step_6_title,
    step_6_description,
    step_7_title,
    step_7_description,
    step_7_image,
  } = directusRecycle;

  return (
    <Layout>
      <SEO title={page_title} description={page_description} />
      <section className={styles.hero}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.textContainer}>
              {hero_title && <h1>{hero_title}</h1>}
              {hero_title && <hr className={styles.hrDark} />}
              {hero_description && <p className={styles.subheading}>{hero_description}</p>}
            </div>
            <Image filename={hero_image.filename_disk} width={500} className={styles.image} />
          </div>
        </div>
      </section>

      <section className={styles.section}>
        <div className={styles.container}>
          <div className={styles.row}>
            <div className={styles.content}>
              <h2>{step_0_title}</h2>
              <p className={styles.subheading}>{step_0_description}</p>
              <Image src="/images/recycle/before-starting@2x.gif" width={690} />

              <h2>{step_1_title}</h2>
              <p className={styles.subheading}>{step_1_description}</p>
              <Image src="/images/recycle/step1@2x.gif" width={690} />

              <h2>{step_2_title}</h2>
              <p className={styles.subheading}>{step_2_description}</p>
              <Image src="/images/recycle/step2@2x.gif" width={690} />

              <h2>{step_3_title}</h2>
              <p className={styles.subheading}>{step_3_description}</p>
              <Image src="/images/recycle/step3@2x.gif" width={690} />

              <h2>{step_4_title}</h2>
              <p className={styles.subheading}>{step_4_description}</p>
              <Image src="/images/recycle/step4@2x.gif" width={690} />

              <h2>{step_5_title}</h2>
              <p className={styles.subheading}>{step_5_description}</p>
              <Image src="/images/recycle/step5@2x.gif" width={690} />

              <h2>{step_6_title}</h2>
              <p className={styles.subheading}>{step_6_description}</p>
              <Image src="/images/recycle/step6@2x.gif" width={690} />

              <h2>{step_7_title}</h2>
              <p className={styles.subheading}>{step_7_description}</p>
              <Image filename={directusRecycle.step_7_image.filename_disk} width={690} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RecyclePage;
